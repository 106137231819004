import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
// import HomeView from '../views/index.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    // component: HomeView,
    component: () => import('@/views/index.vue'),
    redirect: '/home',
    children: [
      // 首页
      {
        path: 'home',
        component: () => import('@/views/home.vue'),
      },
      // 关于我们
      {
        path: 'about',
        component: () => import('@/views/about.vue'),
      },
      // 产品与服务
      {
        path: 'product',
        component: () => import('@/views/product.vue'),
      },
      // 新闻中心
      {
        path: 'news',
        component: () => import('@/views/news.vue'),
      },
      // 人才招聘
      {
        path: 'recruit',
        component: () => import('@/views/recruit.vue'),
      },
      // 联系我们
      {
        path: 'contact',
        component: () => import('@/views/contact.vue'),
      },
    ]
  },
  {
    path: '/newsdetails',
    name: 'newsdetails',
    component: () => import('../views/details/newsdetails.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
